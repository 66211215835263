import * as React from 'react';
import ReactGA from 'react-ga';
import { TextField, Button } from '@material-ui/core';
import {MessageBus} from '../../utilities/MessageBus';

import styles from './AvatarColor.module.css';
import classes from './AvatarColor.module.css';


interface Props {
    onColorSelectionComplete : (hex1:string, hex2:string)=>void;
}

interface State {
    currentColor : number
};


export default class AvatarColor extends React.Component<Props, State> {


    static avatarColors : string[] = [
        "",
        "#ff00a6",
        "#ffbf00",
        "#15df41",
        "#ff0d00",
        "#ff00a6",
        "#9900ff",
        "#c8ff00",
        "#cccccc",
        "#ff6200",
        "#3700ff"
    ]

    static backgroundColors: string[] = [
        "",
        "#FFBAF2",
        "#FFFFBA",
        "#BBFFC2",
        "#FFBABA",
        "#FFBAF2",
        "#EABAFF",
        "#FFFFBA",
        "#FFFFFF",
        "#FFCDBA",
        "#C0BAFF"
    ]

    state = {
        currentColor : 1,
    }

    componentDidMount = () => {
        ReactGA.pageview('/avatarColor');
    }
    
    static sendColorMessage(colorIndex:number){
        MessageBus.Raise("onColorChanged",AvatarColor.avatarColors[colorIndex]);
    }

    setCurrentColor(colorIndex:number){

        AvatarColor.sendColorMessage(colorIndex);

        this.setState({currentColor:colorIndex});
    }

    onButtonClick = (event:any) => {

        event.preventDefault();

        this.props.onColorSelectionComplete(AvatarColor.avatarColors[this.state.currentColor], AvatarColor.backgroundColors[this.state.currentColor]);
    }

    onKeyDownHandler = (e: any) => {
        
        if (e.keyCode === 13) {
            this.onButtonClick(e);
        }
    }
 

    render() {

        let buttonElement = this.state.currentColor > 0 ? (<Button onClick={this.onButtonClick} variant="contained" className={styles.colorSelectNextButton} color="primary" style={{ marginTop: '20px'}}>NEXT</Button>) :  "";
        
        return (
            
            <div className={styles.chooseColor}>
                <h1 className={styles.colorSelectHeader}>Choose an Avatar Color</h1>
                <div className={styles.colorWrapColors}>
                    <form 
                        className={styles.colorForm}
                        onSubmit={this.onButtonClick}
                        onKeyDown={e => this.onKeyDownHandler(e)}
                    >
                        <input 
                            type="button"
                            onClick={() => {this.setCurrentColor(1)}} 
                            onFocus={() => {this.setCurrentColor(1)}} 
                            autoFocus
                            className={this.state.currentColor == 1 ? `${styles.color01} ${styles.colorSelect} ${styles.colorInput}` : `${styles.color01} ${styles.colorInput}`}></input>
                        <input 
                            type="button"
                            onClick={() => {this.setCurrentColor(2)}}
                            onFocus={() => {this.setCurrentColor(2)}}
                            className={this.state.currentColor == 2 ? `${styles.color02} ${styles.colorSelect} ${styles.colorInput}` : `${styles.color02} ${styles.colorInput}`}></input>
                        <input 
                            type="button"
                            onClick={() => {this.setCurrentColor(3)}} 
                            onFocus={() => {this.setCurrentColor(3)}} 
                            className={this.state.currentColor == 3 ? `${styles.color03} ${styles.colorSelect} ${styles.colorInput}` : `${styles.color03} ${styles.colorInput}`}></input>
                        <input 
                            type="button"
                            onClick={() => {this.setCurrentColor(4)}} 
                            onFocus={() => {this.setCurrentColor(4)}} 
                            className={this.state.currentColor == 4 ? `${styles.color04} ${styles.colorSelect} ${styles.colorInput}` : `${styles.color04} ${styles.colorInput}`}></input>
                        <input 
                            type="button"
                            onClick={() => {this.setCurrentColor(5)}} 
                            onFocus={() => {this.setCurrentColor(5)}}
                            className={this.state.currentColor == 5 ? `${styles.color05} ${styles.colorSelect} ${styles.colorInput}` : `${styles.color05} ${styles.colorInput}`}></input>
                        <input 
                            type="button"
                            onClick={() => {this.setCurrentColor(6)}} 
                            onFocus={() => {this.setCurrentColor(6)}} 
                            className={this.state.currentColor == 6 ? `${styles.color06} ${styles.colorSelect} ${styles.colorInput}` : `${styles.color06} ${styles.colorInput}`}></input>
                        <input 
                            type="button"
                            onClick={() => {this.setCurrentColor(7)}} 
                            onFocus={() => {this.setCurrentColor(7)}} 
                            className={this.state.currentColor == 7 ? `${styles.color07} ${styles.colorSelect} ${styles.colorInput}` : `${styles.color07} ${styles.colorInput}`}></input>
                        <input 
                            type="button"
                            onClick={() => {this.setCurrentColor(8)}} 
                            onFocus={() => {this.setCurrentColor(8)}} 
                            className={this.state.currentColor == 8 ? `${styles.color08} ${styles.colorSelect} ${styles.colorInput}` : `${styles.color08} ${styles.colorInput}`}></input>
                        <input 
                            type="button"
                            onClick={() => {this.setCurrentColor(9)}} 
                            onFocus={() => {this.setCurrentColor(9)}} 
                            className={this.state.currentColor == 9 ? `${styles.color09} ${styles.colorSelect} ${styles.colorInput}` : `${styles.color09} ${styles.colorInput}`}></input>
                        <input 
                            type="button"
                            onClick={() => {this.setCurrentColor(10)}}
                            onFocus={() => {this.setCurrentColor(10)}} 
                            className={this.state.currentColor == 10 ? `${styles.color10} ${styles.colorSelect} ${styles.colorInput}` : `${styles.color10} ${styles.colorInput}`}></input>

                       {/*  invisible input element to allow for enter to submit */}
                        <input type="submit" className={styles.invisibleSubmit}></input>

                        {buttonElement}

                    </form>
                    
                </div>
            </div>
            
        )
    }

}












