import * as React from 'react';
import ReactGA from 'react-ga';
import {MessageBus} from '../../utilities/MessageBus';
import { Button } from '@material-ui/core';

import styles from './DeviceToggle.module.css';

interface Props {
  onDeviceToggle : (toggleBoolean : boolean) => void;
}

interface State {

}

export default class DeviceToggle extends React.Component<Props, State> {

  componentDidMount = () => {

    //send pageview for Google Analytics
    ReactGA.pageview('/deviceToggle');
  }

  onYesNoButtonClick = (toggle): void => {

    //conditionally navigate to next screen if yes/no was checked
    if (toggle) {
      this.props.onDeviceToggle(true);
    } else {
      this.props.onDeviceToggle(false);
    }
  }

  render() {
    return (
      <div className={styles.toggleDeviceHolder}>
        <p className={styles.toggleDeviceText}>
          <span className={styles.toggleDeviceTextBlock}>Almost There!</span>

          Would you like to turn on your webcam and microphone so that you can talk with others?
        </p>
        
        <Button onClick={() => {this.onYesNoButtonClick(true)}} value="yes" variant="contained" color="primary" style={{ marginRight: '20px'}} className={styles.toggleButton}>Yes</Button>
        <Button onClick={() => {this.onYesNoButtonClick(false)}} value="no" variant="contained" color="primary" className={styles.toggleButton}>No</Button>
      </div>

    )
  }
}