import * as BABYLON from 'babylonjs';
import { AvatarData } from '../avatars/AvatarData';
import 'babylonjs-loaders';
import { BabylonFileLoaderConfiguration, Scene, PBRMaterial, VertexBuffer, Mesh, Vector3, Material, SimplexPerlin3DBlock, Texture, VideoTexture } from 'babylonjs';
import SocketIOController, { SocketIOSubscriber } from '../scene/SocketIOController';

export class TeleportController implements SocketIOSubscriber{
   
    elapsedTimeSinceLocationChange : number = 0;
    elapsedTimeSinceEnableKey : number = 0;

    constructor(private engine : BABYLON.Engine, private scene : Scene, private camera : BABYLON.FreeCamera, private socketIOController: SocketIOController)
    {
       
        this.socketIOController.AddListener(this);

        this.scene.registerBeforeRender(() => {
            this.elapsedTimeSinceLocationChange += this.engine.getDeltaTime();
            this.elapsedTimeSinceEnableKey += this.engine.getDeltaTime();
        });

        this.scene.onKeyboardObservable.add((kbInfo) => {
                                
            switch(kbInfo.type){
                case BABYLON.KeyboardEventTypes.KEYDOWN:

                //Check for the enable key
                if(kbInfo.event.key == "p"){
                    this.elapsedTimeSinceEnableKey = 0;
                }


                //throttle sending too many requests         
                                     
                if(this.elapsedTimeSinceLocationChange > 500 && this.elapsedTimeSinceEnableKey < 1500){
                    if(kbInfo.event.key == "z"){                        
                        this.elapsedTimeSinceLocationChange = 0;
                        this.socketIOController.SetServerVariable("GERoomSwitch", {roomNumber:0});
                    }
                    else if(kbInfo.event.key == "x"){                       
                        this.elapsedTimeSinceLocationChange = 0;
                        this.socketIOController.SetServerVariable("GERoomSwitch", {roomNumber:1});
                    }
                }
                    

                break;    
            }
        });
    }

    onPlayerConnected =  () => {};
    onRemotePlayerConnected = (playerData: AvatarData) => {};
    onRemotePlayerDisconnected = (playerID: AvatarData) => {};
    onMessage = (messageName: string, message: any) => {};
    
    onGlobalMessage = (messageName: string, message: any) => {     
           
        if(messageName == "GERoomSwitch"){
            this.setRoom(message.roomNumber);
        }
        
    }

    onVariableUpdate = (variableName: string, newValue: any) => {       
      
        if(variableName == "GERoomSwitch"){
            this.setRoom(newValue.roomNumber);
        }
    }
    
    onPlayerPositionsUpdate = (playerData: any) => {};
    onPlayerDataUpdate = (playerData: AvatarData) => {};

   
    public setRoom(roomNumber: number){
        
        if(roomNumber == 0){
            this.camera.position = new BABYLON.Vector3(0,2,7);
            this.camera.rotation = new BABYLON.Vector3(0,Math.PI,0);
        } else if(roomNumber == 1){
            this.camera.position = new BABYLON.Vector3(0,1002,7);
            this.camera.rotation = new BABYLON.Vector3(0,Math.PI,0);
        }
    }

   

  

}