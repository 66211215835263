import * as React from 'react';
import ReactGA from 'react-ga';
import { TextField, Button } from '@material-ui/core';
import {MessageBus} from '../../utilities/MessageBus';

import styles from './BioIntake.module.css';

interface Props {
    onBioDataComplete : (firstName:String, lastName:string, company:string, email:string) => void;
}

interface State {        
    firstNameError : boolean
};


export default class BioIntake extends React.Component<Props, State> {
    
    firstName : any;
    lastName : any;
    company : any;
    email : any;

    state : State = {    
        firstNameError : false
    };

    componentDidMount = () => {
        ReactGA.pageview('/bioIntake');
    }
    
    onButtonClick = (evt : React.MouseEvent | null) => {
        if(this.firstName.value.length > 0){
            this.props.onBioDataComplete(this.firstName.value, this.lastName.value, this.company.value,this.email.value);
        } else {
            this.setState({firstNameError : true});
        }
    }

    onFirstNameChange = (event : any) => {
        MessageBus.Raise("onFirstNameChanged", this.firstName.value);
    }

    onLastNameChange = (event : any) => {
        MessageBus.Raise("onLastNameChanged", this.lastName.value);
    }

    onCompanyNameChange = (event : any) => {
        MessageBus.Raise("onCompanyChanged", this.company.value);
    }

    submitHandler = (e) => {
        e.preventDefault();
        this.onButtonClick(null);
    }

    render() {
        return (
            <div className={styles.enterName}>
                <h1 className={styles.bioIntakeHeader}>Enter Your Information</h1>
                <form className={styles.biodataIntake} noValidate autoComplete="off" onSubmit={this.submitHandler}>

                    <TextField inputRef={el => this.firstName = el} onChange={this.onFirstNameChange} id="standard-basic" label={this.state.firstNameError ? "Please enter a first name" : "First Name"} fullWidth error={this.state.firstNameError} />
                    <TextField inputRef={el => this.lastName = el} onChange={this.onLastNameChange} id="standard-basic" label="Last Name" fullWidth />
                    <TextField inputRef={el => this.company = el} onChange={this.onCompanyNameChange} id="standard-basic" label="Company" fullWidth />
                    <TextField inputRef={el => this.email = el} id="standard-basic" label="Email" fullWidth />
                    <Button type="button" className="biodata" onClick={this.onButtonClick} variant="contained" color="primary" style={{ marginTop: '20px'}} >
                        NEXT
                    </Button>
                </form>
            </div>
            
        )
    }

}












