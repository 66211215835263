import * as BABYLON from 'babylonjs';
import { AvatarData } from "../avatars/AvatarData";
import { iAvatarMediaController } from "../avatars/iAvatarMediaController";
import { LocalAvatarController } from "./LocalAvatarController";
import { Mesh, StandardMaterial, PBRMaterial, Engine, Scalar } from 'babylonjs';
import { RemoteAvatarView } from './RemoteAvatarView';
import {LobbyDefaultConfig} from '../utilities/LobbyConfig';

export class RemoteAvatar{


    public anchor : BABYLON.TransformNode;
    private bodyColliderMesh : BABYLON.Mesh;
    
    localMediaController : iAvatarMediaController | null;

    //temp foo
    volumeIndicator : Mesh;
    maxVolumeIndicator : Mesh;
    videoIndicator : Mesh;

    cameraV2 : BABYLON.Vector2 = BABYLON.Vector2.Zero();
    remoteVatarV2 : BABYLON.Vector2 = BABYLON.Vector2.Zero();

    timeSinceMediaUpdate : number = 0;
    mediaUpdateInterval : number = 1000 / 4;

    labelRenderDistance : number = 15;

    useIndicators : boolean = LobbyDefaultConfig.DevelopmentMode;

    constructor(private engine:BABYLON.Engine, private scene:BABYLON.Scene, private camera:BABYLON.FreeCamera, public remoteAvatarData : AvatarData, private localAvatarController : LocalAvatarController, private avatarInstance : RemoteAvatarView){
        
        //TODO TODO TODO
        //add events for monitoring distance and tuning volume and video on/off for this avatar

        //configure the color of this avatar
        //hide the video signal for now

        this.SetupAvatar(remoteAvatarData, avatarInstance);
        
        scene.registerBeforeRender(()=> {
            this.updateMedia()
        });
    }

    public dispose(){
        this.avatarInstance.dispose();
    }

    public setPosition(x:number, y:number, z:number){
        let cam = this.camera as BABYLON.UniversalCamera;
        let offset = cam.ellipsoid.y * 2;

        this.avatarInstance.setPosition(x, y, z, offset, this.scene);
    }

    public setRotation(x:number, y:number, z:number){
        this.avatarInstance.setRotation(x,y,z);
    }

    

    public setRemoteMediaController(iAvatarMediaController : iAvatarMediaController){

        this.localMediaController = iAvatarMediaController;

       

        if(this.remoteAvatarData.cameraEnabled){
            this.avatarInstance.setWebViewTexture(this.localMediaController.getHTMLVideoElement(), this.scene);
            this.avatarInstance.showWebCam();
        }

        //Update the volume initially
        this.updateMedia();

    }


    public removeRemoteMediaController(){

        this.avatarInstance.hideWebCam();

    }

    public updateAvatarData(remoteAvatarData : AvatarData){
        //todo respond to an update in player data.... 
        //check camera for now
        //long term maybe colors, etc...

        this.remoteAvatarData = remoteAvatarData;

        if(!this.remoteAvatarData.cameraEnabled){
            this.avatarInstance.hideWebCam();
        } else {
            if (this.bodyColliderMesh.intersectsMesh(this.localAvatarController.videoBubble, true)){
                this.avatarInstance.showWebCam();
            }
        }

    }


    private SetupAvatar(avatarData : AvatarData, avatarInstance : RemoteAvatarView){
        avatarInstance.SetColor(avatarData.color);
        avatarInstance.SetName(avatarData.firstName, avatarData.lastName);
        avatarInstance.SetTitle(avatarData.company);
        avatarInstance.SetFace(avatarData.eyes);

        this.anchor = avatarInstance.GetRoot();
        
        this.bodyColliderMesh =  BABYLON.MeshBuilder.CreateBox("bodyColliderMesh", {size:0.5}, this.scene);
        this.bodyColliderMesh.parent = this.anchor;
        this.bodyColliderMesh.visibility = 0;

        this.avatarInstance.hideWebCam();


        console.log("localAvatarController.videoRadius: " + this.localAvatarController.videoRadius);

        if(this.useIndicators)
        {
        //temp foo
        //let volumeTorus = BABYLON.MeshBuilder.CreateTorus("volumeTorus", {diameter:this.localAvatarController.volumeRadius*2, thickness: 0.02, tessellation: 32 },this.scene);
        //volumeTorus.position = new BABYLON.Vector3(0,0.1, 0);
        let volumeMat = new BABYLON.StandardMaterial("volumeTorusMat", this.scene);
        volumeMat.emissiveColor = new BABYLON.Color3(1,0,0);
        //volumeTorus.material = volumeMat;
        //volumeTorus.parent = this.anchor;

        //let maxVolumeTorus = BABYLON.MeshBuilder.CreateTorus("maxVolumeTorus",{diameter:this.localAvatarController.maxVolumeRadius*2, thickness: 0.02, tessellation: 64},this.scene);
        //maxVolumeTorus.position = new BABYLON.Vector3(0,0.1, 0);
        let maxVolumeMat = new BABYLON.StandardMaterial("maxVolumeTorusMat", this.scene);
        maxVolumeMat.emissiveColor = new BABYLON.Color3(0,1,0);
        //maxVolumeTorus.material = maxVolumeMat;
        //maxVolumeTorus.parent = this.anchor;

        //let videoTorus = BABYLON.MeshBuilder.CreateTorus("volumeTorus",{diameter:this.localAvatarController.videoRadius*2, thickness: 0.02, tessellation: 64},this.scene);
        //videoTorus.position = new BABYLON.Vector3(0,0.1, 0);
        let videoTorusMat = new BABYLON.StandardMaterial("videoTorus", this.scene);
        videoTorusMat.emissiveColor = new BABYLON.Color3(0,0,1);
        //videoTorus.material = videoTorusMat;
        //videoTorus.parent = this.anchor;
        
        this.volumeIndicator = BABYLON.MeshBuilder.CreateSphere("volumeTorus", {diameter:0.2 },this.scene);
        this.volumeIndicator.position = new BABYLON.Vector3(0,4.5, 0);
        this.volumeIndicator.material = volumeMat;
        this.volumeIndicator.parent = this.anchor;

        this.maxVolumeIndicator = BABYLON.MeshBuilder.CreateSphere("maxVolumeTorus",{diameter:0.2},this.scene);
        this.maxVolumeIndicator.position = new BABYLON.Vector3(0,4.8, 0);
        this.maxVolumeIndicator.material = maxVolumeMat;
        this.maxVolumeIndicator.parent = this.anchor;

        this.videoIndicator = BABYLON.MeshBuilder.CreateSphere("videoTorus",{diameter:0.2},this.scene);
        this.videoIndicator.position = new BABYLON.Vector3(0,5.1, 0);
        this.videoIndicator.material = videoTorusMat;
        this.videoIndicator.parent = this.anchor;

        this.volumeIndicator.isVisible = false;
        this.maxVolumeIndicator.isVisible = false;
        this.videoIndicator.isVisible = false;
        }
        
        if(avatarData.position && avatarData.position.length >= 3){
            this.setPosition(avatarData.position[0], avatarData.position[1], avatarData.position[2]);
        }
        if(avatarData.rotation && avatarData.rotation.length >= 3){
            this.setRotation(avatarData.rotation[0], avatarData.rotation[1], avatarData.rotation[2]);
        }

    }

    updateMedia = () => {

        this.timeSinceMediaUpdate += this.engine.getDeltaTime();
        if(this.timeSinceMediaUpdate < this.mediaUpdateInterval) return;

        this.timeSinceMediaUpdate = 0;

        this.cameraV2.x = this.camera.position.x;
        this.cameraV2.y = this.camera.position.z;
        this.remoteVatarV2.x = this.anchor.position.x;
        this.remoteVatarV2.y = this.anchor.position.z;
        
        let distance = BABYLON.Vector2.Distance(this.cameraV2, this.remoteVatarV2);

        //Volume
        if(distance > this.localAvatarController.volumeRadius) {
            if(this.useIndicators){
                this.maxVolumeIndicator.isVisible = false;
                this.volumeIndicator.isVisible = false;
            }

            this.localMediaController?.setVolume(0);
            //console.log(distance + ": " + 0);
        } else if(distance <= this.localAvatarController.maxVolumeRadius){
            if(this.useIndicators) {
                this.maxVolumeIndicator.isVisible = true;
                this.volumeIndicator.isVisible = true;
            }

            this.localMediaController?.setVolume(1);
            //console.log(distance + ": " + 1);       
        } else {                   
            if(this.useIndicators) {
                this.volumeIndicator.isVisible = true;
                this.maxVolumeIndicator.isVisible = false;
            }

            let adjustedDistance = distance - this.localAvatarController.maxVolumeRadius;
           
            let v = 1 - (adjustedDistance / (this.localAvatarController.volumeRadius - this.localAvatarController.maxVolumeRadius));
            v = Scalar.Clamp(Math.sqrt(v), 0, 1);

            this.localMediaController?.setVolume(v);
            //console.log(distance + ": " + v);

            //let adjustedT = Math.log10(1-t)/-2;
        }
 
        //Webcam
        if(distance <= this.localAvatarController.videoRadius){
            if(this.useIndicators) this.videoIndicator.isVisible = true;
                    
            if(this.remoteAvatarData.cameraEnabled){
                if(this.localMediaController){
                    this.localMediaController?.playVideo();                        
                    this.avatarInstance.showWebCam();
                }
            }
        }
        else if(distance > this.localAvatarController.videoRadius){
            if(this.useIndicators) this.videoIndicator.isVisible = false;

            if(this.remoteAvatarData.cameraEnabled){
                if(this.localMediaController){
                    this.localMediaController?.stopVideo();
                    this.avatarInstance.hideWebCam();
                }
            }
        }
      
        //Label
        if(distance <= this.labelRenderDistance){
            this.avatarInstance.ShowLabels();
        }
        else{
            this.avatarInstance.HideLabels();
        }
    }

    

}