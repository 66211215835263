import React from 'react';
import ReactDOM from 'react-dom';
import './styles.css';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Switch } from '@material-ui/core';
import BabylonScene from './BabylonScene';
import BioIntake from './Intake/BioIntake';
import AvatarColor from './Intake/AvatarColor';
import Faces from './Intake/Faces';
import RoomCode from './Intake/RoomCode';
import { MessageBus } from '../utilities/MessageBus';
import TestContainers from './Intake/TestContainers';
import { RoomCodeData } from '../utilities/RoomCodeData';
import SideBar from './SceneAddons/SideBar';
import CameraViewPanel from './SceneAddons/CameraViewPanel';
import DeviceToggle from './Intake/DeviceToggle';
import MicrophoneSettings from './Intake/MicrophoneSettings';
import MicAndCameraSettings from './Intake/MicAndCameraSettings';
import SpeakerSettings from './Intake/SpeakerSettings';
import CameraSettings from './Intake/CameraSettings';

import {CustomLoadingScreen} from '../scene/CustomLoadingScreen';

import styles from './PageLayout.module.css';


interface Props {
   
}

interface State {
    
}

export default class PageLayout extends React.Component<Props, State> {
          
    onRoomCodeComplete = (roomCode:String) => {

        MessageBus.Raise("onRoomCodeChanged", roomCode);

        let updatedState = {
            formPage :  (<BioIntake onBioDataComplete={this.onBioDataComplete}></BioIntake>),    
            roomCode : roomCode        
        }

        this.setState(updatedState);
        
    }

    onBioDataComplete = (firstName:String, lastName:string, company:string, email:string) => {
        let updatedState = {
            formPage :  (<AvatarColor onColorSelectionComplete={this.onColorSelectionComplete}></AvatarColor>),
            firstName : firstName,
            lastName : lastName,
            company : company,
            email : email
        }

        this.setState(updatedState);
    }

    onColorSelectionComplete = (hexColor1:string, hexColor2:string) =>{
        let updatedState = {
            formPage :  (<Faces onFaceSelectionComplete={this.onFaceSelectionComplete}></Faces>),
            color : hexColor1,
            backgroundColor: hexColor2

        }
        this.setState(updatedState);
    }

    onFaceSelectionComplete = (faceIndex:number) => {
        
        let updatedState = {

            formPage :  (
                <DeviceToggle onDeviceToggle={this.onDeviceToggle}></DeviceToggle>
            ),
            face : faceIndex
        }

        this.setState(updatedState);
        
    }

    onDeviceToggle = (toggleBoolean: boolean) => {

        let updatedState = {
            deviceToggle: toggleBoolean
        }

        this.setState(updatedState);

        if (toggleBoolean === false) {

            this.onDeviceConfigurationBypass();    

        } else {
            let updatedState = {
                formPage: (
                    <MicAndCameraSettings onMicAndCameraComplete={this.onMicAndCameraComplete}></MicAndCameraSettings>
                )
            }
            
            document.getElementById('settingsHolder').classList.add("micAndCameraSettings");

            this.setState(updatedState);
        }
    }

    onMicAndCameraComplete = () => {

        document.getElementById('settingsHolder').classList.remove("micAndCameraSettings")

        let updatedState = {
            formPage: (
                <SpeakerSettings onSpeakerSettingsComplete={this.onSpeakerSettingsComplete}></SpeakerSettings>
            )
        }
        
        document.getElementById("settingsHolder").classList.add("speakerSettingsHolder");

        this.setState(updatedState);

    }

    onMicrophoneComplete = () => {

        let updatedState = {
            formPage: (                    
                <CameraSettings onCameraSettingsComplete={this.onCameraSettingsComplete}></CameraSettings>
            )
        }

        document.getElementById("settingsHolder").classList.remove("microphoneSettingsHolder");

        this.setState(updatedState);

    }

    onCameraSettingsComplete = () => {
        let updatedState = {
            formPage: (
                <SpeakerSettings onSpeakerSettingsComplete={this.onSpeakerSettingsComplete}></SpeakerSettings>
            )
        }

        document.getElementById("settingsHolder").classList.add("speakerSettingsHolder");

        this.setState(updatedState);
    }

    onSpeakerSettingsComplete = () => {

        document.getElementById("settingsHolder").classList.remove("speakerSettingsHolder");

        this.setState({intakeComplete:true});

        document.getElementById("renderCanvas").classList.add("fullscreenCanvas");

        document.getElementById("body").classList.add("fullscreenBody");

        document.getElementById("body").style.minHeight = '0';

        document.getElementById("renderCanvas").style.top = '0';

        MessageBus.Raise("onAvatarConfigureComplete",true);

        this.setState({
            fullScreenLoading: true
        })
    }


    onDeviceConfigurationBypass = () => {
        /* this.props.onIntakeComplete(); */
        this.setState({intakeComplete:true});

        document.getElementById("renderCanvas").classList.add("fullscreenCanvas");

        document.getElementById("body").classList.add("fullscreenBody");

        document.getElementById("body").style.minHeight = '0';

        document.getElementById("renderCanvas").style.top = '0';

        MessageBus.Raise("onAvatarConfigureComplete",true);

        this.setState({
            fullScreenLoading: true
        })
    }

    
    state = {
        roomCode : "",
        intakeComplete : false,
        formPage :  (<RoomCode onRoomCodeComplete={this.onRoomCodeComplete}></RoomCode>),
        firstName : "",
        lastName : "",
        company : "",
        email : "",
        color : "",
        backgroundColor: "",
        fullScreenLoading: false,
        face : 0,
        deviceToggle: false,
        devicePreviewBoolean: false,
        showAvatarPreview: false,
        showSidebar: false
    }

    handleFullScreenLoadingComplete = ():any => {
        this.setState({
            fullScreenLoading: false
        });

        this.setState({
            showAvatarPreview: true,
            showSidebar: true
        })
    }

    componentDidMount() {
        //AvatarColor.sendColorMessage(0);

        MessageBus.AddListener("OnFullscreenCanvasLoadComplete", this.handleFullScreenLoadingComplete);
    }

    setSelectedColor = (color) => {
        this.setState({
            selectedColor: color
        })
    }
  
    render() {

        RoomCodeData.Load();

        let settingsView = this.state.intakeComplete ? "" : (<div id="settingsHolder" className={styles.settings}>{this.state.formPage}</div>);

        let contentWrapClass = this.state.intakeComplete ? styles.fullScreenBabylon : styles.contentWrap;
        let avatarContainerClass = this.state.intakeComplete ? styles.fullScreenBabylon : styles.avatar;

        return(            
            <div className={contentWrapClass} >
                {this.state.fullScreenLoading === true && 
                    <div className={styles.loadingScreen}>
                        <img src="assets/textures/sb-logo.png" className={styles.imageBack}></img>
                        <img src="assets/textures/sb-spinner.png" className={styles.imageSpinner}></img>

                    </div>
                }
                <div className={avatarContainerClass} id="fullScreenBabylon">
                        {this.state.showSidebar &&
                            <SideBar />
                        }

                        {this.state.showAvatarPreview &&
                            <CameraViewPanel color={this.state.color} backgroundColor={this.state.backgroundColor} face={this.state.face}/>
                        }

                    <BabylonScene></BabylonScene>
                </div>
                {settingsView}
            </div>     
        )
    };
}

/*
<div className={contentWrapClass}>
                <div className={avatarContainerClass}>
                    <BabylonScene></BabylonScene>
                </div>
                {settingsView}
            </div>    



             <TestContainers />   
            */

