


export interface RoomCodeStruct{
    code : string,
    opens : string
    expires : string,
    errorMsg : string,
    slides : Array<[string, string]>,
    textures : string[]
};


export class RoomCodeData {

    static RoomCodeMap : Map<string, RoomCodeStruct> = new Map<string, RoomCodeStruct>();

    public static Load(){

        if(RoomCodeData.RoomCodeMap){

        fetch("config/roomCodes.json")
            .then(res => res.json())
            .then(
            (result) => {
                result.roomCodes.forEach((rc : RoomCodeStruct)=>{
                    RoomCodeData.RoomCodeMap.set(rc.code, rc);
                });

            },(error) => {
                console.log("Error loading room codes");
            console.log(error);
            }
            );
        }
    }


}