
import * as BABYLON from 'babylonjs';

export interface iAvatarData{   
    userID : string
    firstName : string
    lastName : string
    company : string
    color : string
    mouth : number
    eyes : number
    cameraEnabled : boolean
    micEnabled : boolean
    socketID : string
    roomID : string
    position : number[]
    rotation : number[]

}


export class AvatarData implements iAvatarData{  
    userID: string = "";
    firstName: string = "";
    lastName: string = "";
    company: string = "";
    color: string = "#FFFFFF";
    mouth: number = -1;
    eyes: number = -1;
    cameraEnabled: boolean = false;
    micEnabled: boolean = false;
    socketID: string = "";
    roomID : string = ""
    position : number[] = [0,2,0];
    rotation : number[] = [0,0,0];

}