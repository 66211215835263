

interface ILoadingScreen {
  //What happens when loading starts
  displayLoadingUI: () => void;
  //What happens when loading stops
  hideLoadingUI: () => void;
  //default loader support. Optional!
  loadingUIBackgroundColor: string;
  loadingUIText: string;
}

//export class CustomLoadingScreen extends BABYLON.DefaultLoadingScreen implements ILoadingScreen {
export class CustomLoadingScreen implements ILoadingScreen {
    //optional, but needed due to interface definitions
    public loadingUIBackgroundColor: string = "Loading!";
    public loadingUIText: string = "#000000";

    loadingScreenDiv : HTMLElement | null;

    
    private _loadingDiv;
    /** Gets or sets the logo url to use for the default loading screen */
    static DefaultLogoUrl: string = 'assets/textures/sb-logo.png';
    /** Gets or sets the spinner url to use for the default loading screen */
    static DefaultSpinnerUrl: string = 'assets/textures/sb-spinner.png';

    constructor(private _renderingCanvas, private _loadingText, private _loadingDivBackgroundColor) {
      //var _this = this;
      if (_loadingText === void 0) { _loadingText = ""; }
      if (_loadingDivBackgroundColor === void 0) { _loadingDivBackgroundColor = "black"; }
      this._renderingCanvas = _renderingCanvas;
      this._loadingText = _loadingText;
      this._loadingDivBackgroundColor = _loadingDivBackgroundColor;
      // Resize
      
    }

    public displayLoadingUI() {

      if (this._loadingDiv) {
          // Do not add a loading screen if there is already one
          return;
      }

      this._loadingDiv = document.createElement("div");
      this._loadingDiv.classList.add("fullscreenLoading");
      this._loadingDiv.id = "babylonjsLoadingDiv";
      this._loadingDiv.style.opacity = "0";
      this._loadingDiv.style.transition = "opacity 1.5s ease";
      this._loadingDiv.style.pointerEvents = "none";
      // Loading text
    
      // Generating keyframes
      var style = document.createElement('style');
      style.type = 'text/css';
      var keyFrames = "@-webkit-keyframes spin1 {                    0% { -webkit-transform: rotate(0deg);}\n                    100% { -webkit-transform: rotate(360deg);}\n                }                @keyframes spin1 {                    0% { transform: rotate(0deg);}\n                    100% { transform: rotate(360deg);}\n                }";
      style.innerHTML = keyFrames;
      document.getElementsByTagName('head')[0].appendChild(style);
      var svgSupport = !!window.SVGSVGElement;
      // Loading img
      var imgBack = new Image();
      imgBack.src = CustomLoadingScreen.DefaultLogoUrl;
      
      
      /*
      imgBack.style.display = "block"
      imgBack.style.width = "30%";
      imgBack.style.marginLeft = "auto";
      imgBack.style.marginRight = "auto";
      imgBack.style.marginTop = "40%";
      */

     imgBack.style.position = "absolute";
     imgBack.style.left = "50%";
     imgBack.style.marginLeft = "-15%";
     imgBack.style.top = "40%";
     imgBack.style.width = "30%";
      

      /*
      imgBack.style.position = "absolute";
      imgBack.style.left = "50%";
      imgBack.style.marginLeft = "-10%";
      imgBack.style.top = "40%";
      imgBack.style.width = "30%";
      */
      //imgBack.style.height = "40%";
      // Loading spinner
      var imgSpinner = new Image();
      imgSpinner.src = CustomLoadingScreen.DefaultSpinnerUrl;
      
      imgSpinner.style.position = "absolute";
      imgSpinner.style.left = "50%";
      imgSpinner.style.marginLeft = "-15%";
      imgSpinner.style.top = "40%";
      imgSpinner.style.width = "30%";
      /*
      imgSpinner.style.left = "50%";
      imgSpinner.style.top = "40%";
      //imgSpinner.style.width = "40%";
      imgSpinner.style.height = "40%";
      */
      imgSpinner.style.animation = "spin1 0.75s infinite linear";
      imgSpinner.style.webkitAnimation = "spin1 0.75s infinite linear";
      imgSpinner.style.transformOrigin = "50% 50%";
      imgSpinner.style.webkitTransformOrigin = "50% 50%";

      /*
      if (!svgSupport) {
          var logoSize = { w: 16, h: 18.5 };
          var loadingSize = { w: 30, h: 30 };
          // set styling correctly
          imgBack.style.width = logoSize.w + "vh";
          imgBack.style.height = logoSize.h + "vh";
          imgBack.style.left = "50%"; //"calc(50% - " + logoSize.w / 2 + "vh)";
          imgBack.style.top =  "40%"; //"calc(50% - " + logoSize.h / 2 + "vh)";
          //imgSpinner.style.width = loadingSize.w + "vh";
          imgSpinner.style.height = loadingSize.h + "vh";
          imgSpinner.style.left = "calc(50% - " + loadingSize.w / 2 + "vh)";
          imgSpinner.style.top = "calc(50% - " + loadingSize.h / 2 + "vh)";

          console.log('BBBBBBBBBBB');
      }
      */

      this._loadingDiv.appendChild(imgBack);
      this._loadingDiv.appendChild(imgSpinner);
      this.resizeLoadingUI();
      window.addEventListener("resize", this.resizeLoadingUI);
      this._loadingDiv.style.backgroundColor = this._loadingDivBackgroundColor;
      document.body.appendChild(this._loadingDiv);
      this._loadingDiv.style.opacity = "1";      
    }
  
    public hideLoadingUI() {
     
      var _this = this;
        if (!this._loadingDiv) {
            return;
        }
        var onTransitionEnd = function () {
            if (!_this._loadingDiv) {
                return;
            }
            if (_this._loadingDiv.parentElement) {
                _this._loadingDiv.parentElement.removeChild(_this._loadingDiv);
            }
            window.removeEventListener("resize", this._resizeLoadingUI);
            _this._loadingDiv = null;
        };
        this._loadingDiv.style.opacity = "0";
        this._loadingDiv.addEventListener("transitionend", onTransitionEnd);
    }


    public resizeLoadingUI = () => {
      var canvasRect = this._renderingCanvas.getBoundingClientRect();
      var canvasPositioning = window.getComputedStyle(this._renderingCanvas).position;
      if (!this._loadingDiv) {
          return;
      }
      this._loadingDiv.style.position = (canvasPositioning === "fixed") ? "fixed" : "absolute";
      this._loadingDiv.style.left = canvasRect.left + "px";
      this._loadingDiv.style.top = canvasRect.top + "px";
      this._loadingDiv.style.width = canvasRect.width + "px";
      this._loadingDiv.style.height = canvasRect.height + "px";

      console.log(canvasRect);
    };
}