import 'fm.liveswitch';
import { rejects } from 'assert';
import { resolve } from 'url';


export interface DeviceCounts {
    microphonesCount : number,
    camerasCount : number,
    speakersCount : number
}

export interface MediaDevice {
    id : string,
    name : string
}


export class LocalMediaController{

    private localMedia : fm.liveswitch.LocalMedia | null;

    private htmlVideoElement : HTMLVideoElement;

    private microphoneInUse : boolean = false;
    private cameraInUse: boolean = false;

    private requestMicrophoneUse : boolean = false;
    private requestCameraUse : boolean = false;
    
    private audioLevel : number = 0;

    private microphonesCount : number = 0;
    private camerasCount : number = 0;
    private speakersCount : number = 0;

    private haveDeviceCount : boolean = false;
    private haveDeviceLists : boolean = false;

    private videoSource : fm.liveswitch.SourceInput;
    private audioSource : fm.liveswitch.SourceInput;

    private startOrStopOperationInProgress : boolean = false;
    private isRunning : boolean = false;

    private audioLevellisteners : {(level:number):void}[] = [];

    private audioSources : fm.liveswitch.SourceInput[];
    private videoSources : fm.liveswitch.SourceInput[];
    private speakerSources : MediaDevice[] = [];

    private currentSpeaker : MediaDevice | null = null;

    constructor(){

        //Attempt to get the device counts right away to speed up later transactions
        this.getDeviceCounts();

    }


    /**************************************************************
     * 
     * Public API
     * 
     **************************************************************/

    
     public startVideo = () : Promise<boolean> => {

        this.requestMicrophoneUse = false;
        this.requestCameraUse = true;

        return new Promise((resolve,reject)=>{

            //Check if camera is already in use
            if(this.isRunning && this.cameraInUse){
                resolve(true);
                return;
            }

            if(this.startOrStopOperationInProgress){
                reject("Cannot start video while a previous start or stop operation is in progress");
                return;
            }
            
            this.start().then(()=>{
                resolve(true);
            }).fail((ex)=>{
                reject(ex);
            });

        });
     }

     public stopVideo = () : Promise<boolean> => {

        this.requestCameraUse = false; 

        return new Promise((resolve,reject)=>{

            //check if this already is the case
            if(!this.isRunning || !this.cameraInUse){
                resolve(true);
                return;
            }

            //reject if another operation is in progress
            if(this.startOrStopOperationInProgress){
                reject("Cannot stop video while a previous start or stop operation is in progress");
                return;
            }

            //Check if we still need to run with just the microphone
            if(this.microphoneInUse){

                this.start().then(()=>{
                    resolve(true);
                    return;
                }).fail((ex)=>{
                    reject(ex);
                    return;
                });

            } else {

                //Stop all devices
                this.stop().then(()=>{
                    this.cameraInUse = false;
                    resolve(true);
                    return;
                }).fail((ex)=>{
                    this.cameraInUse = false;
                    reject(ex);
                    return;
                });

            }            

        });
    }

     public startAudio = () : Promise<boolean> => {

        this.requestMicrophoneUse = true;
        this.requestCameraUse = false;

        return new Promise((resolve,reject)=>{

            //Check if camera is already in use
            if(this.isRunning && this.microphoneInUse){
                resolve(true);
                return;
            }

            if(this.startOrStopOperationInProgress){
                reject("Cannot start audio while a previous start or stop operation is in progress");
                return;
            }


            this.start().then(()=>{
                resolve(true);
            }).fail((ex)=>{
                reject(ex);
            });

        });
    }

    public stopAudio = () : Promise<boolean> => {

        this.requestMicrophoneUse = false; 

        return new Promise((resolve,reject)=>{
            console.log("11");
            //check if this already is the case
            if(!this.isRunning || !this.microphoneInUse){
                console.log("12");
                resolve(true);
                return;
            }

            //reject if another operation is in progress
            if(this.startOrStopOperationInProgress){
                console.log("13");
                reject("Cannot stop video while a previous start or stop operation is in progress");
                return;
            }

            //Check if we still need to run with just the microphone
            if(this.cameraInUse){
                console.log("14");
                this.start().then(()=>{
                    console.log("15");
                    resolve(true);
                    return;
                }).fail((ex)=>{
                    reject(ex);
                    return;
                });

            } else {
                console.log("16");
                //Stop all devices
                this.stop().then(()=>{
                    console.log("17");
                    this.cameraInUse = false;
                    resolve(true);
                    return;
                }).fail((ex)=>{
                    this.cameraInUse = false;
                    reject(ex);
                    return;
                });

            }            

        });
    }

    public startAudioAndVideo = () : Promise<boolean> => {

        this.requestCameraUse = true;
        this.requestMicrophoneUse = true;

        console.log('A1');

        return new Promise((resolve,reject)=>{
            console.log('A2');
            //Check if camera is already in use
            if(this.isRunning && this.cameraInUse && this.microphoneInUse){
                console.log('A7');
                resolve(true);
                return;
            }

            console.log('A3');
            if(this.startOrStopOperationInProgress){
                reject("Cannot start audio and video while a previous start or stop operation is in progress");
                return;
            }
            console.log('A4');
            this.start().then(()=>{
                console.log('A5');
                resolve(true);
            }).fail((ex)=>{
                console.log('A6');
                reject(ex);
            });

        });
     }

    public stopAudioAndVideo = () : Promise<boolean> => {
        return new Promise((resolve,reject)=>{
            this.stop().then(()=>{
                resolve(true);
            }).fail((ex)=>{
                console.log(ex);
                reject(ex);
            })
        });
    }

    public changeVideoSource = (id:string, name:string) : Promise<boolean> => {

        //Set the video source of this class
        this.videoSource = new fm.liveswitch.SourceInput(id, name);

        return new Promise((resolve,reject) => {

            //If we are not running, return immediately
            if(!this.isRunning){
                resolve(true);
                return;
            }

            
            //Change the video source
            if(this.localMedia){
                this.localMedia.changeVideoSourceInput(this.videoSource)
                .then(()=>{
                    resolve(true);
                })
                .fail((ex)=>{
                    console.log("Failed to change video source");
                    console.log(ex);
                    reject(ex);
                });
            } else {
                //This should not happen and indiactes a mismatch with isRunning variable
                resolve(true);
                return;
            }
            
        });
    }

    public changeAudioSource = (id:string, name:string) : Promise<boolean> => {

        //Set the video source of this class
        this.audioSource = new fm.liveswitch.SourceInput(id, name);

        console.log("1");

        return new Promise((resolve,reject) => {
            console.log("2");
            //If we are not running, return immediately
            if(!this.isRunning){
                resolve(true);
                return;
            }

            console.log("3");

            //Change the video source
            if(this.localMedia){
                console.log("4");
                this.localMedia.changeAudioSourceInput(this.audioSource)
                .then(()=>{
                    console.log("5");
                    resolve(true);
                })
                .fail((ex)=>{
                    console.log("Failed to change video source");
                    console.log(ex);
                    reject(ex);
                });
            } else {
                console.log("6");
                //This should not happen and indiactes a mismatch with isRunning variable
                resolve(true);
                return;
            }
            
        });
    }

    public AddAudioListener = (listener : (num:Number)=>void) => {
        this.audioLevellisteners.push(listener);
    }

    public RemoveAudioListener = (listener : (num:Number)=>void) => {
        let index = this.audioLevellisteners.findIndex( (e) => {return listener == e;});
        if(index >= 0){
            this.audioLevellisteners.splice(index, 1);
        }
    }

    public getVideoDevices = () : Promise<MediaDevice[]> => {

        return new Promise<MediaDevice[]>((resolve, reject) => {
            if(!this.localMedia){
                reject("Local media not created. Could not retrieve video devices");
                return;
            } else {

                this.localMedia?.getVideoSourceInputs()
                .then((sources)=>{

                    this.videoSources = sources;
                    let mediaDevices : MediaDevice[] = [];

                    if(sources){
                        for(let i in sources){
                            let sourceInput : fm.liveswitch.SourceInput = sources[i]; 
                            mediaDevices.push({id:sourceInput.getId(), name: sourceInput.getName()});
                        }
                    }
                    resolve(mediaDevices);

                }).fail((exception)=>{            
                    console.log("Failed to retrieve video sources");
                    console.log(exception);
                    reject(exception);
                });

            }

        });

    }

    public getAudioDevices = () : Promise<MediaDevice[]> => {

        return new Promise<MediaDevice[]>((resolve, reject) => {
            if(!this.localMedia){
                reject("Local media not created. Could not retreive audio devices");
                return;
            } else {

                this.localMedia?.getAudioSourceInputs()
                .then((sources)=>{

                    this.audioSources = sources;
                    let mediaDevices : MediaDevice[] = [];

                    if(sources){
                        for(let i in sources){
                            let sourceInput : fm.liveswitch.SourceInput = sources[i]; 
                            mediaDevices.push({id:sourceInput.getId(), name: sourceInput.getName()});
                        }
                    }
                    resolve(mediaDevices);

                }).fail((exception)=>{            
                    console.log("Failed to retrieve video sources");
                    console.log(exception);
                    reject(exception);
                });
            }

        });

    }

    public getVideoDevice = () : MediaDevice | null => {
        if(!this.localMedia) return null;

        let sourceInput : fm.liveswitch.SourceInput = this.localMedia.getVideoInput()

        return {id: sourceInput.getId(), name: sourceInput.getName()};    
    }

    public getAudioDevice = () : MediaDevice | null => {
        if(!this.localMedia) return null;

        let sourceInput : fm.liveswitch.SourceInput = this.localMedia.getAudioInput()

        return {id: sourceInput.getId(), name: sourceInput.getName()};    
    }

    public getSpeakerDevices = () : MediaDevice[] => {
        return this.speakerSources;
    }

    public setCurrentSpeaker = (id: string, name: string) : void => {
        this.currentSpeaker = {id:id, name:name};
    }

    public getCurrentSpeaker = () : MediaDevice | null => {
        return this.currentSpeaker;
    }


    //GETTERS

    public getLocalMedia = () : fm.liveswitch.LocalMedia => {
        return this.localMedia;
    }

    public getHTMLVideoElement = () : HTMLVideoElement => {
        return this.htmlVideoElement;
    }

    public isMicrophoneInUse = () : boolean =>{
        return this.microphoneInUse;
    }

    public isCameraInUse = () : boolean =>{
        return this.cameraInUse;
    }

    public getAudioLevel = () => {
        return this.audioLevel;
    }

    public getIsStartOrStopOperationInProgress = () : boolean => {
        return this.startOrStopOperationInProgress;
    }

    public getIsRunning = () : boolean => {
        return this.isRunning;
    }

    
    /**************************************************************
     * 
     * Private Helpers
     * 
     **************************************************************/

    public OnAudioLevel = (n:number) : void => {
        this.audioLevellisteners.forEach((listener)=>{
            listener(n);
        });
    }


    public getDeviceCounts = (): Promise<DeviceCounts> => {

        return new Promise((resolve,reject)=>{

            if(this.haveDeviceCount){
                resolve({microphonesCount: this.microphonesCount, camerasCount: this.camerasCount, speakersCount: this.speakersCount});
                return;
            } else {

                //Use the native api's for getting a list of devices
                navigator.mediaDevices.enumerateDevices()
                .then((deviceInfos : MediaDeviceInfo[]) => {
                    
                    for (let i = 0; i !== deviceInfos.length; ++i) {
                        const deviceInfo : MediaDeviceInfo = deviceInfos[i];
                        console.log(deviceInfo);
                        
                        if (deviceInfo.kind === 'audioinput') {
                            this.microphonesCount++;
                        } else if (deviceInfo.kind === 'audiooutput') {
                            this.speakersCount++;        
                            this.speakerSources.push({id:deviceInfo.deviceId, name: deviceInfo.label});
                        } else if (deviceInfo.kind === 'videoinput') {
                            this.camerasCount++;;   
                        } else {
                            console.log('Misc source/device: ', deviceInfo);
                        }
                        
                      }
                
                      this.haveDeviceCount = true;
                      resolve({microphonesCount: this.microphonesCount, camerasCount: this.camerasCount, speakersCount: this.speakersCount});
                      return;

                })
                .catch((error)=>{
                    console.log(error);
                    reject();
                    return;
                });
            }            

        });

        
    }


    private start = () : fm.liveswitch.Future<boolean> => {

        console.log('A8');

        let promise = new fm.liveswitch.Promise<boolean>();

        //Check if local media is already running that we first need to stop
        if(this.localMedia){

            console.log('A9');

            this.stop().then(()=>{
                console.log('A10');
                this.doStart().then(()=>{
                    console.log('A11');
                    promise.resolve(true);
                }).fail((ex)=>{
                    console.log(ex.message);
                    promise.reject(ex);
                });
            }).fail((ex)=>{
                console.log('A12');
                console.log(ex);
                //Try a new start anyways
                this.doStart().then(()=>{
                    console.log('A13');
                    promise.resolve(true);
                }).fail((ex)=>{
                    console.log('A14');
                    console.log(ex.message);
                    promise.reject(ex);
                });
            })


        } else {
            this.doStart().then(()=>{
                console.log('A15');
                promise.resolve(true);
            }).fail((ex)=>{
                console.log('A16');
                console.log(ex.message);
                promise.reject(ex);
            });
        }

        

        return promise;

       
    }

    private doStart = () : fm.liveswitch.Future<boolean> => {    
        let promise = new fm.liveswitch.Promise<boolean>();

        console.log('A17');
        if(this.isRunning){
            console.log('A18');
            promise.resolve(true);
            return;
        }

       //Set the proper device configurations
        let audioConfig : MediaTrackConstraints | boolean = this.requestMicrophoneUse;
        let videoConfig : MediaTrackConstraints | boolean = this.requestCameraUse;    

        if(this.requestMicrophoneUse){    
            console.log('A19');
            audioConfig = {
                sampleSize: 8,
                channelCount: 1,
                echoCancellation: true,
            };

            //Check if we need to specify specific device
            if(this.audioSource){
                console.log('A22');
                audioConfig.deviceId = { ideal: this.audioSource.getId() };
            } 
        }  

        if(this.requestCameraUse){    
            console.log('A20');     
            videoConfig = {
                width: 320,
                height: 240,
                frameRate: 15
            };

            //Check if we need to specify specific device
            if(this.videoSource){
                console.log('A21', this.videoSource.getId());
                videoConfig.deviceId = { ideal: this.videoSource.getId() };
            } 
        }

        
        //Create local media instance
        this.localMedia = new fm.liveswitch.LocalMedia(audioConfig, videoConfig, false);

        //Add audio level listener
        this.localMedia.addOnAudioLevel(this.OnAudioLevel);     

        
        //TODO TODO TODO
        //SIMULCAST SETTINGS
        /*
        var videoEncodings : fm.liveswitch.VideoEncodingConfig[] = [];
        videoEncodings.push(new fm.liveswitch.VideoEncodingConfig());
        videoEncodings.push(new fm.liveswitch.VideoEncodingConfig());   


        
        videoEncodings[0].setBitrate(512);
        videoEncodings[0].setFrameRate(15);

        videoEncodings[1].setBitrate(128);
        videoEncodings[1].setFrameRate(15);
        videoEncodings[1].setScale(0.5);

        this.localMedia.setVideoEncodings(videoEncodings);
        */

            
        //Mark operation in progress
        this.startOrStopOperationInProgress = true;

        console.log('A23');

        //Start local media
        this.localMedia.start().then((_localMedia) => {

            console.log('A24');

            //Checking if camera/mic are to be marked as in use. Can't rely on requestCamera or mic since async operation could have changed them
            if(audioConfig){
                console.log('A26');
                this.microphoneInUse = true;
            } else {
                console.log('A27');
                this.microphoneInUse = false;
            }

            if(videoConfig){
                console.log('A28');
                this.cameraInUse = true;
            } else {
                console.log('A29');
                this.cameraInUse = false;
            }
            
            
            //Denote that operation is complete and we are now running
            this.startOrStopOperationInProgress = false;
            this.isRunning = true;

            //Set our html video element accordingly
            this.htmlVideoElement = this.localMedia.getView().getElementsByTagName("Video")[0] as HTMLVideoElement;    
                
            promise.resolve(true);       

        }).fail((ex) => {

            console.log('A25');

            //Denote that operation is complete and we are NOT running
           
            this.startOrStopOperationInProgress = false;
            this.isRunning = false;
           
               
            console.log(ex.message);
            promise.reject(ex);

        });     
        
        console.log('A30');
        return promise;
     
    }

    private stop = () : fm.liveswitch.Future<boolean> => {   
        
        
        let promise = new fm.liveswitch.Promise<boolean>();       
        
        if(this.startOrStopOperationInProgress){

          
            //we can't stop while a start is in progress            
            promise.reject(new fm.liveswitch.Exception("Unable to stop media while it is starting"));

        } else {
          
            if(this.localMedia){

                this.startOrStopOperationInProgress = true;
            
                this.localMedia.stop().then(()=>{                      
               
                    this.isRunning = false;
                    this.startOrStopOperationInProgress = false;

                    this.microphoneInUse = false;
                    this.cameraInUse = false;
                    
                    this.localMedia.destroy();
                    this.localMedia = null;
                    promise.resolve(true);                    
    
                }).fail((ex)=>{

                    this.isRunning = false;
                    this.startOrStopOperationInProgress = false;

                    this.microphoneInUse = false;
                    this.cameraInUse = false;
                  
                    console.log("Failed to stop local media");
                    console.log(ex);
                    promise.reject(ex);
                });
            }
            
        }

        return promise;
    }
}