import React, { Component } from 'react';
import * as BABYLON from 'babylonjs';
/* import avatarHead from './avatarHead.png'; */
import { AvatarConfigureScene } from '../../scene/AvatarConfigureScene';
import avatarHead from './avatarHead-overlay.svg';
import styles from './CameraViewPanel.module.css';
import {liveswitchController}  from '../../liveSwitch/LiveSwitchController'
import BabylonScene from '../BabylonScene';
import { faLessThan } from '@fortawesome/free-solid-svg-icons';

interface Props {
  color: string,
  backgroundColor: string,
  face: number
}
interface State {
  hasVideo: boolean,
  backgroundColor: string
}

export default class CameraViewPanel extends Component<Props, State> {
  state = {
    hasVideo: true,
    backgroundColor: ''
  }

  componentDidMount = () => {
    let hex = this.props.color;

    let regularColor = BABYLON.Color3.FromHexString(hex);
    let highValueColor = regularColor.add(new BABYLON.Color3(0.5, 0.5, 0.5));

    highValueColor.r *= 255;
    highValueColor.g *= 255;
    highValueColor.b *= 255;

    console.log(highValueColor);

   /*  this.setState({
      backgroundColor: highValueColor,
    }) */

    console.log("FACE", this.props.face)

    let localMedia = liveswitchController.localMediaController.getLocalMedia();

    if (localMedia == null) {
      this.setState({
        hasVideo: false
      })

      return;
    } else {
      let layoutManager = new fm.liveswitch.DomLayoutManager(document.getElementById("videoHolder"));
      layoutManager.setLocalView(localMedia.getView());

      if (localMedia.getVideoInput() === undefined) {
        this.setState({
          hasVideo: false
        })
      }
    }
   
  }

  render() {

    let svgStyles = {
      fill: this.props.color
    }

    let faceClass;

    switch(this.props.face) {
      case 0:
        faceClass = 'face01'
        break;
      case 1:
        faceClass = 'face03'
        break;
      case 2:
        faceClass = 'face04'
        break;
      case 3:
        faceClass = 'face07'
        break;
      case 4:
        faceClass = 'face08'
        break;
      case 5:
        faceClass = 'face09'
        break;
      case 6:
        faceClass = 'face10'
        break;
      case 7:
        faceClass = 'face05'
        break;
    }

    let placeholderStyles = {
      backgroundColor: `${this.props.backgroundColor}`
    }

    return (
      <div className={styles.cameraViewHolder}>
        <svg className={styles.avatarFaceBorder} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 170 170" style={svgStyles}>
          <defs>
          </defs>
          <title>avatarHead-overlay</title>
          <g id="Layer_1" data-name="Layer 1">
            <path className="cls-1" d="M158.85,0H9.24A9.24,9.24,0,0,0,0,9.24V160a10,10,0,0,0,10,10H160a10,10,0,0,0,10-10V11.15A11.15,11.15,0,0,0,158.85,0ZM161,159a2,2,0,0,1-2,2H11a2,2,0,0,1-2-2V11a2,2,0,0,1,2-2H43.09c1.93,5.6,6.1,9.24,10.73,9.24h62.36c4.63,0,8.8-3.64,10.73-9.24H159a2,2,0,0,1,2,2Z"/>
            </g>
        </svg>

        <div id="videoHolder" className={styles.videoWrapper}>

        </div>

        {!this.state.hasVideo  && 
          <div 
            className={styles.avatarOverlay}
            style={placeholderStyles}
          >
            <div className={`${faceClass} ${styles.faceBackground}`}>
            </div>
          </div>
          
        }

      </div>
    )
  }
}
