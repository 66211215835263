import 'fm.liveswitch';
import { iAvatarMediaController } from "../avatars/iAvatarMediaController";
import {liveswitchController}  from './LiveSwitchController';

export class RemoteStreamController implements iAvatarMediaController {

    public userID : string;

    private onConnectionLost : (remoteStreamController:RemoteStreamController) => void;
    private remoteMedia : fm.liveswitch.RemoteMedia;
    private audioStream : fm.liveswitch.AudioStream;
    private videoStream :  fm.liveswitch.VideoStream;
    private connection : fm.liveswitch.SfuDownstreamConnection;
    private htmlVideoElement : HTMLVideoElement;


    private connectionHasAudio : boolean;
    private connectionHasVideo : boolean;
    private connectionConfig : fm.liveswitch.ConnectionConfig;

    private videoIsPlaying : boolean = false;

    constructor(private channel : fm.liveswitch.Channel, private connectionInfo : fm.liveswitch.ConnectionInfo ){
        this.userID = connectionInfo.getUserId();

        this.connectionHasAudio = connectionInfo.getHasAudio();
        this.connectionHasVideo = connectionInfo.getHasVideo();
    
    }

    public connect(onConnectionLost : (remoteStreamController:RemoteStreamController) => void) : fm.liveswitch.Future<boolean>{

        console.log("CONNECT REMOTE STREAM");

        this.onConnectionLost = onConnectionLost;
        let promise = new fm.liveswitch.Promise<boolean>();

        this.remoteMedia = new fm.liveswitch.RemoteMedia();
        let currentSpeaker = liveswitchController.localMediaController.getCurrentSpeaker();
        if(currentSpeaker){
            let speakerSink = new fm.liveswitch.SinkOutput(currentSpeaker.id, currentSpeaker.name);
            this.remoteMedia.setAudioSinkOutput(speakerSink);
        }

        this.remoteMedia.getAudioSinkOutputs().then((result)=>{
            console.log(result);
            result.forEach((x)=>{
                console.log(x);
            })
        }).fail((error)=>{console.log(error);});
        

        if(this.connectionInfo.getHasAudio()){
            this.audioStream = new fm.liveswitch.AudioStream(this.remoteMedia);
        }

        if(this.connectionInfo.getHasVideo()){
            this.videoStream = new fm.liveswitch.VideoStream(this.remoteMedia);

            let remoteEncodings = this.connectionInfo.getVideoStream().getSendEncodings();
            console.log(remoteEncodings);
        }
        
        if(this.connectionInfo.getHasAudio() && this.connectionInfo.getHasVideo()){
            this.connection = this.channel.createSfuDownstreamConnection(this.connectionInfo, this.audioStream, this.videoStream);           
        } else if(this.connectionInfo.getHasAudio() ){
            this.connection = this.channel.createSfuDownstreamConnection(this.connectionInfo, this.audioStream);
        } else if(this.connectionInfo.getHasVideo() ){
            this.connection = this.channel.createSfuDownstreamConnection(this.connectionInfo, this.videoStream);
        }

        this.connection.open().then(()=>{
            this.htmlVideoElement = this.remoteMedia.getView().getElementsByTagName("Video")[0] as HTMLVideoElement;
            this.connection.addOnStateChange( (c : fm.liveswitch.ManagedConnection)=>{this.onConnectionStateChange(c)});

            this.connectionConfig = this.connection.getConfig();
            
            this.remoteMedia.getAudioSinkOutputs().then((result)=>{
                console.log(result);
                result.forEach((x)=>{
                    console.log(x);
                })
            }).fail((error)=>{console.log(error);});
            //

            //testing foo
           // setInterval(()=>{
            //    console.log("Bandwidth: " + this.videoStream.getRemoteBandwidth() + " -- " + this.videoStream.getLocalBandwidth());
            //},250);


            /*
            this.remoteMedia.getAudioSinkOutputs().then((sinkOutputs)=>{
                sinkOutputs.forEach( (sinkOutput) =>{
                    console.log(sinkOutput);
                })
            });
            */

          


            promise.resolve(true);  
        }).fail((ex)=>{
            console.log(ex);
            console.log("Streaming connection failed to new remote connection");            
            promise.reject(ex);
        }); 

        return promise;
    }


    onConnectionStateChange(c : fm.liveswitch.ManagedConnection){
        if (c.getState() == fm.liveswitch.ConnectionState.Closing || c.getState() == fm.liveswitch.ConnectionState.Failing) {
            console.log("Connection lost: " + this.userID);
            console.log("Lost reason:", c.getState());
            this.onConnectionLost(this);
        }
    }

 

    /***************************************************************************************************
     * 
     * 
     * interface iAvatarMediaController
     * 
     * 
     ***************************************************************************************************/

    setVolume(level: number): void {

      
        if(!this.audioStream) return;

        if(level <= 0){
            this.remoteMedia.getAudioTrack().setVolume(0);    
            this.audioStream.setMuted(true);
        } else {
            //Unmute if we are muted
            if(this.audioStream?.getMuted()){
                this.audioStream.setMuted(false);                            
            }
            this.remoteMedia.getAudioTrack().setVolume(level);    
        }
    }

    playAudio(): void {
        
        //this.audioStream.setMuted(false);
        this.remoteMedia.setAudioMuted(false);
    }

    stopAudio(): void {
        //this.audioStream.setMuted(true);
        this.remoteMedia.setAudioMuted(true);
    }

    playVideo(): void {

        if(this.videoIsPlaying) return;
        this.videoIsPlaying = true;

        this.remoteMedia.setVideoMuted(false);
        this.videoStream.setMuted(false);

       
        /*
        if(this.videoIsPlaying) return;
        this.videoIsPlaying = true;

        console.log("X");
        console.log(this.connection.getLocalDescription());
        console.log(this.connection.getRemoteDescription());

      
        let config = this.connection.getConfig();
       
        config.setRemoteVideoDisabled(false);        
        config.setLocalVideoMuted(false);
        config.setLocalVideoDisabled(false);
        config.setVideoDirection("recvonly");

        console.log("A");
        console.log(config);

        this.connection.update(config).then(()=>{

        }).fail((ex)=>{
            console.log("Z");
            console.log(this.connection.getLocalDescription());
            console.log(this.connection.getRemoteDescription());

            console.log(ex);
        });
        */
      
    }

    stopVideo(): void {

        /*
        coming soon
        let config = downstreamConnection.getConfig();
        config.setRemoteAudioDisabled(true);
        await downstreamConnection.update(config);

        //However, there is currently an issue in our library that prevents this from working in JavaScript. We are internally tracking the issue as LS-4722 and hope to address in an upcoming release.
        */

        if(!this.videoIsPlaying) return;
        this.videoIsPlaying = false;

       
        
        this.remoteMedia.setVideoMuted(true);
        this.videoStream.setMuted(true);

        /*
        this.connection.update(this.connection.get).then(()=>{

        }).fail((ex)=>{
            console.log("Z");
            console.log(this.connection.getLocalDescription());
            console.log(this.connection.getRemoteDescription());

            console.log(ex);
        });
        */


        /*
        let config = this.connection.getConfig();
        config.setRemoteVideoDisabled(true);
        config.setLocalVideoDisabled(true);
        //config.setLocalVideoMuted(true);
        //config.setVideoDirection("sendonly");

        console.log('A');
        this.connection.update(config).then(()=>{

        }).fail((ex)=>{
            console.log("Z");
            console.log(this.connection.getLocalDescription());
            console.log(this.connection.getRemoteDescription());

            console.log(ex);
        });
        */
       

       
    /*
        let config = this.connection.getConfig();
       
        config.setRemoteVideoDisabled(true);        
        config.setLocalVideoMuted(false);
        config.setLocalVideoDisabled(false);
        config.setVideoDirection("inactive");

        console.log("B");
        console.log(config);

        this.connection.update(config).then(()=>{
            console.log("Y");
            console.log(this.connection.getLocalDescription());
            console.log(this.connection.getRemoteDescription());
        }).fail((ex)=>{
            console.log(ex);
        });
*/
        




        
        
    }

    getHTMLVideoElement(): HTMLVideoElement {
        return this.htmlVideoElement;
    }


}