import * as React from 'react';
import ReactGA from 'react-ga';
import styles from './SideBar.module.css';
import { MessageBus } from '../../utilities/MessageBus';
import { AvatarData } from '../../avatars/AvatarData';
import SideBarMenu from './SideBarMenu';

interface Props {
}

interface State {
  open: boolean,
  attendees: any[],
  showMenu: boolean,
  hasScrollBar: boolean
};

export default class SideBar extends React.Component<Props, State> {
  state : State = {
    open : false,
    attendees : [],
    showMenu: false,
    hasScrollBar: false
  }

  handleNavShow = ():any => {

    ReactGA.event({
      action: 'Showed attendee sidebar',
      category: 'Button'
    })

    this.setState({
      open: true
    })

    let toShow = document.getElementsByClassName('attendeeText');

    let toShowObject = Object.keys(toShow);

    setTimeout(function() {
      toShowObject.forEach(key => {
        toShow[key].style.display = "block";
      })
    }, 300)
    
    let showButton = document.getElementById("showButton");

    showButton.style.display = "none";

    let viewportWidth = window.innerWidth;

    if (viewportWidth >= 480) {
      document.getElementById("sideBar").style.width = "250px";
    } else {
      document.getElementById("sideBar").style.width = "150px";
    }
  }

  handleNavHide = (): any => {
    this.setState({
      open: false
    })

    let toHide = document.getElementsByClassName('attendeeText');

    let toHideObject = Object.keys(toHide);

    toHideObject.forEach(key => {
      toHide[key].style.display = "none";
    })

    let showButton = document.getElementById("showButton");

    setTimeout(function() {
      showButton.style.display = "block"
    }, 200)

    document.getElementById("sideBar").style.width = "0";
  }


  updateAttendeeList = (listOfAttendees : AvatarData[]) => {

    let helperArray = [];

    listOfAttendees.forEach((avatarData)=>{
      helperArray.push({
        "name": avatarData.firstName + " " + avatarData.lastName,
        "company": avatarData.company,
        "userID": avatarData.userID
      });
    });

    let element = document.getElementById('sideBar');

    this.setState({
      attendees: helperArray
    }, function () {
      if (element.offsetHeight < element.scrollHeight) {
        this.setState({
          hasScrollBar: true,
        })
      } else {
        this.setState({
          hasScrollBar: false,
        })

      }
    })
  }

  componentDidMount = () =>{

    MessageBus.AddListener("OnAttendeeListChange", this.updateAttendeeList);

    window.addEventListener('resize', this.handleResize);

    let element = document.getElementById('sideBar');

    if (element.offsetHeight < element.scrollHeight) {
      this.setState({
        hasScrollBar: true,
      })
    }
  }

  handleResize = () => {
    if (window.innerWidth >= 480 && this.state.open) {
      document.getElementById("sideBar").style.width = "250px";
    } else if (window.innerWidth < 480 && this.state.open) {
      document.getElementById("sideBar").style.width = "150px";
    }
  }

  handleEntryClick = (e: any) => {

    console.log(e.target.id);

    this.setState({
      showMenu: !this.state.showMenu
    })
  }

  render() {   

    return (

      <span className={styles.sideBarHolder}>
        <button onClick={this.handleNavShow} id="showButton" className={styles.sideBarShowButton}></button>

        <span className={styles.sideBar} id="sideBar">
          <button 
            className={this.state.hasScrollBar ? `${styles.sideBarCloseButton} ${styles.sideBarCloseButtonScroll}` : `${styles.sideBarCloseButton}`} 
            onClick={this.handleNavHide}
          ></button>

          <h1 className={styles.attendeesHeader}>Current Attendees:</h1>

          <ul className={styles.attendeesList}>
            {this.state.attendees.map((attendee, i) => {
              return (
                <>
                  <li key={attendee.userID} className={styles.attendeesEntry} id={attendee.userID} onClick={(e) => this.handleEntryClick(e)}>
                    <p key={i} className={`${styles.attendeeName} attendeeText`}>{attendee.name}</p>

                    <p key={i + 1} className={`${styles.attendeeCompany} attendeeText`}>{attendee.company}</p>
                  </li>
                  {/* {this.state.showMenu &&
                    <SideBarMenu attendeeId={attendee.userID} />
                  } */}
                </>
              )
            })}
          </ul>
        </span>

      </span>
      
    )
  }
}