import * as React from 'react';
import ReactGA from 'react-ga';
import { TextField, Button } from '@material-ui/core';
import {MessageBus} from '../../utilities/MessageBus';
import styles from './Faces.module.css';

interface Props {
    onFaceSelectionComplete : (hex:number)=>void;
}

interface State {

};


export default class Faces extends React.Component<Props, State> {

    state = {
        currentFace : 0,
    }

    setCurrentFace(faceIndex:number){

        MessageBus.Raise("onFaceChanged", faceIndex);

        this.setState({currentFace:faceIndex});
    }

    onButtonClick = (event:any) => {

        event.preventDefault();

        this.props.onFaceSelectionComplete(this.state.currentFace);
    }

    onKeyDownHandler = (e: any) => {
        if (e.keyCode === 13) {
            this.onButtonClick(e);
        }
    }

    componentDidMount = () => {
        //send pageview for Google Analytics
        ReactGA.pageview('/avatarFace');
    }

    render() {

        //
        let buttonElement = this.state.currentFace >= 0 ? (<Button onClick={this.onButtonClick} variant="contained" color="primary" style={{ marginTop: '20px'}}>NEXT</Button>) :  "";
        
        return (
            
            <div className={styles.chooseFace}>
                <h1 className={styles.faceSelectHeader}>Choose a Face</h1>
                <p className={styles.subHeadP}>This face will be displayed if you should choose to turn off your webcam.</p>

                <div className={styles.colorWrapFaces}>
                    <form 
                        className={styles.facesForm}
                        onSubmit={this.onButtonClick}
                        onKeyDown={e => this.onKeyDownHandler(e)}
                    >
                        <input 
                            type="button"
                            autoFocus
                            onClick={() => {this.setCurrentFace(0)}} 
                            onFocus={() => {this.setCurrentFace(0)}} 
                            className={this.state.currentFace == 0 ? `${styles.face01} ${styles.faceInput} ${styles.faceSelect}` : `${styles.face01} ${styles.faceInput}`}></input>
                            
                        <input 
                            type="button"
                            onClick={() => {this.setCurrentFace(1)}} 
                            onFocus={() => {this.setCurrentFace(1)}} 
                            className={this.state.currentFace == 1 ? `${styles.face03} ${styles.faceInput} ${styles.faceSelect}` : `${styles.face03} ${styles.faceInput}`}></input>
                        <input 
                            type="button"
                            onClick={() => {this.setCurrentFace(2)}} 
                            onFocus={() => {this.setCurrentFace(2)}} 
                            className={this.state.currentFace == 2 ? `${styles.face04} ${styles.faceInput} ${styles.faceSelect}` : `${styles.face04} ${styles.faceInput}`}></input>
                        <input 
                            type="button"
                            onClick={() => {this.setCurrentFace(3)}} 
                            onFocus={() => {this.setCurrentFace(3)}} 
                            className={this.state.currentFace == 3 ? `${styles.face07} ${styles.faceInput} ${styles.faceSelect}` : `${styles.face07} ${styles.faceInput}`}></input>
                        <input 
                            type="button"
                            onClick={() => {this.setCurrentFace(4)}} 
                            onFocus={() => {this.setCurrentFace(4)}} 
                            className={this.state.currentFace == 4 ? `${styles.face08} ${styles.faceInput} ${styles.faceSelect}` : `${styles.face08} ${styles.faceInput}`}></input>
                        <input 
                            type="button"
                            onClick={() => {this.setCurrentFace(5)}} 
                            onFocus={() => {this.setCurrentFace(5)}} 
                            className={this.state.currentFace == 5 ? `${styles.face09} ${styles.faceInput} ${styles.faceSelect}` : `${styles.face09} ${styles.faceInput}`}></input>
                        <input 
                            type="button"
                            onClick={() => {this.setCurrentFace(6)}} 
                            onFocus={() => {this.setCurrentFace(6)}} 
                            className={this.state.currentFace == 6 ? `${styles.face10} ${styles.faceInput} ${styles.faceSelect}` : `${styles.face10} ${styles.faceInput}`}></input>
                        <input 
                            type="button"
                            onClick={() => {this.setCurrentFace(7)}} 
                            onFocus={() => {this.setCurrentFace(7)}} 
                            className={this.state.currentFace == 7 ? `${styles.face05} ${styles.faceInput} ${styles.faceSelect}` : `${styles.face05} ${styles.faceInput}`}></input>                

                        {/* invisible submit button to allow for "enter" to submit to work */}
                        <input type="submit"  className={styles.invisibleSubmit}></input>
                    </form>
                </div >
                {buttonElement}
            </div>
            
        )
    }

}












