import React, { Component } from 'react';
import styles from './CameraSettings.module.css';
import ReactGA from 'react-ga';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faVideo} from '@fortawesome/free-solid-svg-icons';
import { FormGroup, Select, Button, MenuItem } from '@material-ui/core';
import {liveswitchController}  from '../../liveSwitch/LiveSwitchController';
import {DeviceCounts, MediaDevice}  from '../../liveSwitch/LocalMediaController';
import CameraViewPanel from '../SceneAddons/CameraViewPanel';
import {MessageBus} from '../../utilities/MessageBus';

interface Props {
  onCameraSettingsComplete: () => void;
}
interface State {
  useCamera: boolean,
  cameraError: boolean,
  cameraErrorMsg : string,
  currentCamera : MediaDevice | null | undefined,
  cameraList : MediaDevice[]
}

export default class CameraSettings extends Component<Props, State> {

  state = {
    useCamera: true,
    cameraError: false,
    cameraErrorMsg : "",
    currentCamera : undefined,
    cameraList : []
  }

  localMediaChangeInProgress : boolean = false;
  initialLoadComplete = false;

  handleNextButtonClick = () => {
    if(this.localMediaChangeInProgress || !this.initialLoadComplete) return;
    this.props.onCameraSettingsComplete();
  }

  handleMuteCameraClick = () => {

    if(this.localMediaChangeInProgress) return;

    this.localMediaChangeInProgress = true;

    let useCamera = !this.state.useCamera;

    if(useCamera){

      liveswitchController.localMediaController.startVideo().then(()=>{
        this.setState( {useCamera: true, cameraError:false});
        this.localMediaChangeInProgress = false;

        MessageBus.Raise("onUseCameraChanged", {useCamera: true, htmlVideoElement : liveswitchController.localMediaController.getHTMLVideoElement()});

        //Check if we need devices still
        if(!this.state.cameraList || this.state.cameraList.length == 0){
          this.fetchDevices();
        }

      }).catch((ex)=>{
        console.log("Failed starting camera");
        console.log(ex);
        this.setState( {useCamera: false, cameraError:true, cameraErrorMsg: "Failed to start camera"});

        this.localMediaChangeInProgress = false;

        MessageBus.Raise("onUseCameraChanged", {useCamera: false, htmlVideoElement : liveswitchController.localMediaController.getHTMLVideoElement()});
      });

    } else {
    
      liveswitchController.localMediaController.stopVideo().then(()=>{ 
        this.setState( {useCamera: false, cameraError:false});
        this.localMediaChangeInProgress = false;
        MessageBus.Raise("onUseCameraChanged", {useCamera: false, htmlVideoElement : liveswitchController.localMediaController.getHTMLVideoElement()});
      }).catch((ex)=>{
        console.log("Failed stopping camera");
        this.setState( {useCamera: false, cameraError:true, cameraErrorMsg: "Failed to stop camera"});
        this.localMediaChangeInProgress = false;
        MessageBus.Raise("onUseCameraChanged", {useCamera: false, htmlVideoElement : liveswitchController.localMediaController.getHTMLVideoElement()});

      });

    }
  }

  componentDidMount = () =>{

    ReactGA.pageview('/cameraSettings');

    liveswitchController.localMediaController.getDeviceCounts()
    .then((deviceCounts : DeviceCounts)=>{

      //Check if we have at least 1 mic to try to start
      if(deviceCounts.camerasCount > 0){

        //Try to start the mic       
        liveswitchController.localMediaController.startVideo().then(()=>{

          this.setState( {useCamera: true});
          MessageBus.Raise("onUseCameraChanged", {useCamera: true, htmlVideoElement : liveswitchController.localMediaController.getHTMLVideoElement()});

          
          this.fetchDevices();
          
          this.initialLoadComplete = true;

        }).catch((ex)=>{
          console.log("Failed starting the camera");
          console.log(ex);
          this.setState({useCamera: false, cameraError:true, cameraErrorMsg: "Failed to start the camera"});
          this.initialLoadComplete = true;
        });


      } else {
        //if no cameras were found on machine
        console.log("Camera count returned zero");
        this.setState({useCamera: false, cameraError:true, cameraErrorMsg: "Could not locate any system cameras"});
        this.initialLoadComplete = true;
      }

    })
    .catch((ex)=>{
      //System error with getting count, not related to no cameras
      console.log("Unable to get device counts");
      console.log(ex);
      this.setState({useCamera: false, cameraError:true, cameraErrorMsg: "System would not provide a count of system cameras"});
      this.initialLoadComplete = true;
    });
    
   
  }

  fetchDevices = () => {
    this.localMediaChangeInProgress = true;
    //We succeeded starting the mic. Try and get a list of available mics         
    liveswitchController.localMediaController.getVideoDevices().then((mediaDevices)=>{ 
            
      let currentVideoDevice : MediaDevice = liveswitchController.localMediaController.getVideoDevice();
    
      this.setState( {useCamera: true, cameraError:false, cameraList: mediaDevices, currentCamera: currentVideoDevice});
      this.localMediaChangeInProgress = false;

    }).catch((ex)=>{
      console.log(ex);
      console.log("Failed getting a list of devices");
      this.setState( {useCamera: true, cameraError:true, cameraErrorMsg: "Failed getting a list of cameras"});
      this.localMediaChangeInProgress = false;
    });
  }

  onCameraDeviceChange = (event : any) => {

    if(this.localMediaChangeInProgress) return;

    this.localMediaChangeInProgress = true;

    if(this.state.cameraList && this.state.cameraList.length > 0){

      let deviceID = event.target.value;
      let deviceName = "";

      let i=0;
      for(i=0; i<this.state.cameraList.length; ++i){
          if(this.state.cameraList[i].id == deviceID){
              deviceName = this.state.cameraList[i].name;
              
              liveswitchController.localMediaController.changeVideoSource(deviceID, deviceName).then(()=>{
                this.setState({currentCamera: this.state.cameraList[i], cameraError: false});
                this.localMediaChangeInProgress = false;
              }).catch((ex)=>{
                console.log("Failed to change audio devices");
                console.log(ex);
                this.setState({cameraError: true, cameraErrorMsg: "Failed to change cameras"});
                this.localMediaChangeInProgress = false;
              });

              
              break;
          }
      }

    } else {
      //Ignore any change. Its just placeholders in the dropdown
    }
  }

  render() {

    let currentVideoDeviceID : string = "";
    let cameraInputMenuItems : JSX.Element[] = [];

    //Get the current mic
    if(this.state && this.state.currentCamera != undefined){
      currentVideoDeviceID = this.state.currentCamera.id;
    }; 

    //Populate the list of mic dropdown        
    if(this.state.cameraList && this.state.cameraList.length > 0){
      cameraInputMenuItems = this.state.cameraList.map(    (mediaDevice : MediaDevice)=>{
        return <MenuItem key={mediaDevice.id} value={mediaDevice.id}>{mediaDevice.name}</MenuItem>;
      });

    } else {
      currentVideoDeviceID = "Unknown";
      cameraInputMenuItems.push(<MenuItem key={"Unknown"} value={"Unknown"}>{"No cameras found"}</MenuItem>);
    }

    let buttonClasses;
    //show pseudoelement (red X) if camera is muted
    if (this.state.useCamera === true) {
      buttonClasses = `${styles.cameraToggle}`;
    } else {
      buttonClasses = `${styles.cameraToggle} ${styles.cameraToggleMute}`
    }


    return (
      <div className={styles.cameraSettingsHolder}>

        <h1 className={styles.cameraSettingsHeader}>You should see your face in the avatar screen</h1>

        {this.state.cameraError && <p className={styles.cameraError}>{this.state.cameraErrorMsg}</p>}

        <div className={styles.cameraSelectHolder}>
          <h2 className={styles.cameraSelectHeader}>Camera</h2>

          <FormGroup  key = {"FormGroup"} row className={styles.formGroupCameraSelect}>
              <Select 
                labelId="demo-simple-select-label"  
                id="demo-simple-select" 
                value={currentVideoDeviceID} 
                onChange={this.onCameraDeviceChange} 
                MenuProps={{ className: 'cameraSelectMenu' }}
              >                                                   
                  {cameraInputMenuItems}
              </Select>                    
          </FormGroup>

          <button className={buttonClasses} id="cameraToggle" onClick={this.handleMuteCameraClick}>
            <FontAwesomeIcon className={styles.cameraIconToggle} icon={faVideo} />
          </button>

          <Button className={styles.cameraNextButton} variant="contained" color="primary" onClick={this.handleNextButtonClick}>Next</Button>
        </div>

      </div>
    )
  }
}
