import React, { useState } from 'react';
import './App.css';
import ReactGA from 'react-ga';

import BabylonScene from './components/BabylonScene';
import PageLayout from './components/PageLayout';
import FullScreenCanvas from './components/FullScreenCanvas';

ReactGA.initialize('UA-172694653-1');

function App() {

  


  return (
    <React.Fragment>
      <PageLayout/>
    </React.Fragment>
  );
}

export default App;


/*


const[intakeComplete, setIntakeComplete] = useState(false);

  const onIntakeComplete = () => {
    setIntakeComplete(true);

    console.log("LET GO");
  }

  let pageToLoad = intakeComplete ? <PageLayout onIntakeComplete={onIntakeComplete}/> : <FullScreenCanvas>;</FullScreenCanvas>

  */