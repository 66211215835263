import * as BABYLON from 'babylonjs';
import 'babylonjs-loaders';
import {Vector3, Mesh, Scalar} from 'babylonjs';

export class PlayerClickNavigationController {

    feetRoot : BABYLON.TransformNode;
    feetGraphic : BABYLON.Mesh;
    feetTextureTask : BABYLON.TextureAssetTask;

    canWalk : boolean = false;
    
    walkableMeshes : BABYLON.Mesh[] = [];
    navigationPlugin : BABYLON.RecastJSPlugin;

    TWO_PI = Math.PI * 2;

    latestWalkAnimation : BABYLON.Animatable;
    latestTurnAnimation : BABYLON.Animatable;

    camera : BABYLON.FreeCamera;   

    constructor(private scene : BABYLON.Scene) {}

    load = () : Promise<boolean> => {
        return new Promise<boolean>((resolve,reject)=>{        
            let assetsManager = new BABYLON.AssetsManager(this.scene);
            assetsManager.useDefaultLoadingScreen = false;

            this.feetTextureTask = assetsManager.addTextureTask("feetImageTask", "./assets/textures/walk.png");

            assetsManager.onFinish = (tasks) => {       
                //Create feet cursor
                this.feetRoot = new BABYLON.TransformNode("FeetRoot", this.scene);
                this.feetRoot.rotationQuaternion = null;

                this.feetGraphic = BABYLON.Mesh.CreatePlane("FeetGraphic", 1, this.scene);
                this.feetGraphic.parent = this.feetRoot;
                this.feetGraphic.position = new BABYLON.Vector3(0, 0, 0);
                this.feetGraphic.rotation.x = BABYLON.Tools.ToRadians(90);
                this.feetGraphic.isPickable = false;

                let feetMaterial : BABYLON.PBRMaterial = new BABYLON.PBRMaterial("feetMat", this.scene);
                feetMaterial.transparencyMode = 2;
                feetMaterial.unlit = true;  
                feetMaterial.albedoTexture = this.feetTextureTask.texture;
                this.feetTextureTask.texture.hasAlpha = true;
                feetMaterial.useAlphaFromAlbedoTexture = true;
                feetMaterial.freeze();
                
                this.feetGraphic.material = feetMaterial;   

                resolve();
               
            };
            assetsManager.load();
        });
    }  

    public SetNewWalkableData (newWalkableMeshes : Mesh[], newNavigationPlugin : BABYLON.RecastJSPlugin, camera : BABYLON.FreeCamera){
        if(this.walkableMeshes){
            for(let i = 0; i < this.walkableMeshes.length; i++){
                if(this.walkableMeshes[i].actionManager){
                    this.walkableMeshes[i].actionManager.dispose();
                }
            }
        }

        this.walkableMeshes = newWalkableMeshes;
        this.navigationPlugin = newNavigationPlugin;
        this.camera = camera;

        //Setup walking logic
        this.canWalk = true;

        if(this.walkableMeshes)
        {
            for(let i = 0; i < this.walkableMeshes.length; i++)
            {
                this.walkableMeshes[i].actionManager = new BABYLON.ActionManager(this.scene);
                this.walkableMeshes[i].actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPickTrigger, ()=> {     
                    if(this.canWalk){
                        let currentPosition = new BABYLON.Vector3(this.camera.position.x, this.camera.position.y - (this.camera.ellipsoid.y * 2), this.camera.position.z); 
                        let targetPosition : BABYLON.Vector3 = this.navigationPlugin.getClosestPoint(this.feetRoot.position);

                        let pathPoints = this.navigationPlugin.computePath(currentPosition, targetPosition);

                        let step = 1; //Start at step 1 because 0 is going to be some point very close to the camera
                        this.MovePlayerAlongPath(step, pathPoints);
                    }
                }));
            }
            this.scene.onPointerObservable.add(this.onPointerObservable);
        }
    }

    private MovePlayerAlongPath(step : number, pathPoints : BABYLON.Vector3[]) {
        if(this.latestWalkAnimation) this.latestWalkAnimation.stop();
        if(this.latestTurnAnimation) this.latestTurnAnimation.stop();

        //Get animation variables
        let from = new BABYLON.Vector3(this.camera.position.x, this.camera.position.y, this.camera.position.z);
        let to = pathPoints[step];
        to.y += this.camera.ellipsoid.y * 2;

        let distance = BABYLON.Vector3.Distance(from, to);
        let unitsPerSecond = 7;
        
        let fps = 60;
        let framecount = (distance / unitsPerSecond) * fps;

        this.latestWalkAnimation = BABYLON.Animation.CreateAndStartAnimation('cameraPositionAnimation', this.camera, 'position', fps, framecount, from, to, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT, undefined, ()=>{
            step++;
            if(step < pathPoints.length){
                this.MovePlayerAlongPath(step, pathPoints);
            }   
        });

        //Calculate rotation
        let angle : number = Math.atan2(to.x - from.x, to.z - from.z);    
        let delta = angle - this.camera.rotation.y;

        //Limit rotation to 180 degrees max
        while(Math.abs(delta) > Math.PI){
            if(delta > 0){
                angle -= this.TWO_PI;                                    
            } else {
                angle += this.TWO_PI;
            }
            delta = angle - this.camera.rotation.y;
        }
        
        let turnEasingFunction = new BABYLON.QuadraticEase();
        turnEasingFunction.setEasingMode(BABYLON.EasingFunction.EASINGMODE_EASEINOUT);

        this.latestTurnAnimation = BABYLON.Animation.CreateAndStartAnimation('cameraRotationAnimation', this.camera, 'rotation.y', fps, Scalar.Clamp(framecount, fps * .25, fps * .5), this.camera.rotation.y, angle, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT, turnEasingFunction);
    }

    private onPointerObservable = (pointerInfo : BABYLON.PointerInfo) =>{      
        switch (pointerInfo.type) {
            case BABYLON.PointerEventTypes.POINTERDOWN:
                //console.log("POINTER DOWN");
                break;
            case BABYLON.PointerEventTypes.POINTERUP:
                //console.log("POINTER UP");
                break;
            case BABYLON.PointerEventTypes.POINTERMOVE:
                //console.log("POINTER MOVE");
                //console.log(this.scene);

                let pickResult = this.scene.pick(this.scene.pointerX, this.scene.pointerY);

                if (this.navigationPlugin && pickResult && pickResult.hit && pickResult.pickedPoint) {
                    if(this.walkableMeshes.some(x => x === pickResult?.pickedMesh)){
                        let footPosition = this.navigationPlugin.getClosestPoint(pickResult.pickedPoint);
                        
                        let maximumRange = 30;

                        if(BABYLON.Vector3.Distance(this.camera.position, footPosition) < maximumRange){
                            this.feetGraphic.isVisible = true;
                            this.canWalk = true;

                            let angle : number = Math.atan2( footPosition.x - this.camera.position.x, footPosition.z - this.camera.position.z);                        
                            this.updateFeetPosition(footPosition, angle);

                            return;
                        }
                    } 
                }

                this.feetGraphic.isVisible = false;
                this.canWalk = false;
                
                break;
            case BABYLON.PointerEventTypes.POINTERWHEEL:
                //console.log("POINTER WHEEL");
                break;
            case BABYLON.PointerEventTypes.POINTERPICK:
                //console.log("POINTER PICK");
                break;
            case BABYLON.PointerEventTypes.POINTERTAP:
                //console.log("POINTER TAP");
                break;
            case BABYLON.PointerEventTypes.POINTERDOUBLETAP:
                //console.log("POINTER DOUBLE-TAP");
                break;
        }
    }

    private updateFeetPosition = (targetPosition : Vector3, angle : number) => {

        this.feetRoot.position.x = targetPosition.x;
        this.feetRoot.position.y = targetPosition.y + .1;
        this.feetRoot.position.z = targetPosition.z;

        let down = new BABYLON.Vector3(0, -1, 0);
        let ray = new BABYLON.Ray(this.feetRoot.absolutePosition, down, 1);

        let hit = this.scene.pickWithRay(ray);

        if(hit != null){
            let hitAngle = hit.getNormal(true);
            
            if(hitAngle != null){
                this.feetRoot.rotation = (hitAngle);
                this.feetGraphic.rotation.z = -angle + this.feetRoot.rotation.y;
            }
        }
    }
}