import 'fm.liveswitch';
import {iRemoteStreamSubscriber} from './iRemoteStreamSubscriber';
import { RemoteStreamController } from './RemoteStreamController';
import { MessageBus } from '../utilities/MessageBus';

export class RemoteScreenShareController {
    

    private connection : fm.liveswitch.SfuDownstreamConnection;

    private remoteMedia : fm.liveswitch.RemoteMedia;
    private videoStream :  fm.liveswitch.VideoStream;

    public htmlVideoElement : HTMLVideoElement;

    constructor(private channel : fm.liveswitch.Channel, private screenshareChannelName : string){
        channel.addOnRemoteUpstreamConnectionOpen(this.onRemoteConnection);
    }


    private onRemoteConnection = (remoteConnectionInfo : fm.liveswitch.ConnectionInfo) =>{

        this.remoteMedia = new fm.liveswitch.RemoteMedia();
        this.videoStream = new fm.liveswitch.VideoStream(this.remoteMedia);
        this.connection = this.channel.createSfuDownstreamConnection(remoteConnectionInfo, null, this.videoStream); 

        this.connection.open().then(()=>{

            this.htmlVideoElement = this.remoteMedia.getView().getElementsByTagName("Video")[0] as HTMLVideoElement;
            this.connection.addOnStateChange( (c : fm.liveswitch.ManagedConnection)=>{this.onConnectionStateChange(c)});

            MessageBus.Raise("StartScreenShare", this);
           
        }).fail((ex)=>{
            console.log(ex);            
        }); 
        
    }

    
    onConnectionStateChange(c : fm.liveswitch.ManagedConnection){
        if (c.getState() == fm.liveswitch.ConnectionState.Closing || c.getState() == fm.liveswitch.ConnectionState.Failing) {
            console.log("Screenshare Connection lost");

            console.log(this.screenshareChannelName);

            MessageBus.Raise("ScreenshareStreamStopped", {screenshareChannelName:this.screenshareChannelName});

            //TODO TODO 
            //raise event of screen share
           
        }
    }


    

 

   




}