import React, { Component } from 'react'
import styles from './SpeakerSettings.module.css';
import ReactGA from 'react-ga';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeUp} from '@fortawesome/free-solid-svg-icons';
import { Button, FormGroup, Select, MenuItem } from '@material-ui/core';
import { Sound } from 'babylonjs';
import {DeviceCounts, MediaDevice}  from '../../liveSwitch/LocalMediaController';
import {liveswitchController}  from '../../liveSwitch/LiveSwitchController';


interface Props {
  onSpeakerSettingsComplete: () => void;
}
interface State {
  testSpeakers: boolean,
  speakerList : MediaDevice[],
  speakerCount : number,
  currentSpeaker : MediaDevice | null | undefined,
}

export default class SpeakerSettings extends Component<Props, State> {
  state = {
    testSpeakers: false,
    speakerList: [],
    speakerCount: 0,
    currentSpeaker: undefined,
  }

  testAudio: any;

  testSpeakers = ():any => {
    //Google Analytics event
    ReactGA.event({
      action: 'Tested speakers',
      category: 'Button'
    })

    this.setState({
      testSpeakers: true
    })

    this.testAudio.play();

    //Code to run once test has ended
    this.testAudio.onended = () => {
      this.setState({
        testSpeakers: false,
      })

      document.getElementById("testSpeakersButton").style.color = 'black';
      document.getElementById("testSpeakersButton").style.borderColor = 'black';

      this.testAudio.pause();
      this.testAudio.currentTime = 0;
    }
  }

  stopTestSpeakers = () => {

    this.setState({
      testSpeakers: false
    })

    //Change button border back to black
    document.getElementById("testSpeakersButton").style.color = 'black';
    document.getElementById("testSpeakersButton").style.borderColor = 'black';

    this.testAudio.pause();
    this.testAudio.currentTime = 0;
  }

  handleNextButtonClick = () => {

    this.setState({
      testSpeakers: false
    })

    this.testAudio.pause();
    this.testAudio.currentTime = 0;

    this.props.onSpeakerSettingsComplete();
  }


  componentDidMount = () =>{

    ReactGA.pageview('/speakerSettings');

    //set testAudio property to be the HTML audio element
    this.testAudio = document.querySelector('audio');

    liveswitchController.localMediaController.getDeviceCounts()
    .then((deviceCounts : DeviceCounts)=>{

      //Check if we have at least 1 mic to try to start
      if(deviceCounts.speakersCount > 0){

        let speakerList = liveswitchController.localMediaController.getSpeakerDevices();
        this.setState({speakerList: speakerList, speakerCount: deviceCounts.speakersCount });

        if(speakerList){
          for(let i in speakerList){
            let device = speakerList[i];
            if(device.id == "default"){
              this.setState({currentSpeaker: device});
              break;
            }
          }
        }
      } else {
        console.log("Browser does not support selecting speakers");
      }

    })
    .catch((ex)=>{
      console.log("Unable to get device counts");
      console.log(ex);
    });
  }

  onSpeakerChange = (event : any) => {

    if(this.state.speakerList && this.state.speakerList.length > 0){

      let deviceID = event.target.value;
      let deviceName = "";

      let i=0;
      for(i=0; i<this.state.speakerList.length; ++i){
          if(this.state.speakerList[i].id == deviceID){

              deviceName = this.state.speakerList[i].name;
              liveswitchController.localMediaController.setCurrentSpeaker(deviceID, deviceName );              
              this.setState({currentSpeaker: {id: deviceID, name: deviceName}}, () => {
                console.log(this.state.currentSpeaker.id);
                this.testAudio.setSinkId(this.state.currentSpeaker.id);
              });
              
              break;
          }
      }

    } else {
      //Ignore any change. Its just placeholders in the dropdown
    }
  }

  render() {


    let currentSpeakerDeviceID : string = "";
    let speakerMenuItems : JSX.Element[] = [];

    //Get the current speaker
    if(this.state && this.state.currentSpeaker != undefined){
      currentSpeakerDeviceID = this.state.currentSpeaker.id;
    }; 

    //Populate the list of speaker dropdown        
    if(this.state.speakerList && this.state.speakerList.length > 0){
      speakerMenuItems = this.state.speakerList.map((mediaDevice : MediaDevice)=>{
        return <MenuItem key={mediaDevice.id} value={mediaDevice.id}>{mediaDevice.name}</MenuItem>;
      });

    } else {
      currentSpeakerDeviceID = "Unknown";
      speakerMenuItems.push(<MenuItem key={"Unknown"} value={"Unknown"}>{"Browser default speaker"}</MenuItem>);
    }

    let buttonText;

    //Change test button styles and text based on if speaker test is happening
    if (this.state.testSpeakers === false) {
      buttonText = "Test Speakers";
    } else {
      document.getElementById("testSpeakersButton").style.color = 'grey';
      document.getElementById("testSpeakersButton").style.borderColor = 'grey';
      buttonText = "Playing..."
    }

    return (
      <div className={styles.speakerSettingsHolder}>
        <audio src="https://www.kozco.com/tech/piano2.wav"></audio>
        <h1 className={styles.speakerSettingsHeader}>Click the button to test your speakers</h1>

        <button onClick={this.testSpeakers} id="testSpeakersButton" className={styles.speakerPreview}>
          <FontAwesomeIcon icon={faVolumeUp} className={styles.volumeUpIcon}></FontAwesomeIcon>
  
          <span className={styles.speakersButtonText}>{buttonText}</span>
            
        </button>
        
        {this.state.testSpeakers && 
          <button onClick={this.stopTestSpeakers} className={styles.stopButton}></button>
        }

        <h2 className={styles.speakerSelectHeader}>Speakers</h2>

        <FormGroup  key = {"FormGroup"} row className={styles.formGroupSpeakerSelect}>
            <Select 
              labelId="speakerLabel"  
              value={currentSpeakerDeviceID} 
              onChange={this.onSpeakerChange} 
              MenuProps={{ className: 'speakerSelectMenu' }}
            >                                                   
                {speakerMenuItems}
            </Select>                    
        </FormGroup>
        
        <Button onClick={this.handleNextButtonClick} className={styles.speakerNextButton} variant="contained" color="primary">Next</Button>
        {/* <p className={styles.speakerHelpText}>How to adjust your speakers</p> */}
      </div>
    )
  }
}
